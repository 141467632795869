import FooterBackgroundMobile from 'assets/footer/footer-texture-mobile.svg'
import FooterBackgroundTablet from 'assets/footer/footer-texture-tablet.svg'
import FooterBackground from 'assets/footer/footer-texture.svg'
import HipaaImage from 'assets/footer/hipaa-badge.svg'
import { breakpoints, devices } from 'hooks/useMediaQueries'
import styled from 'styled-components'

import { Div, H2, P, Span } from '../../styled/utils'
import colors from '../../styles/colors'
import { LetsWorkTogetherButton } from '../common/LetsWorkTogetherButton'
import { GeneralWrapper } from '../common/styledUtils'
import { FooterLinkSection } from './FooterLinkSection'
import Legal from './Legal'
import { footerLinks } from './footerLinks'
import {
  adobeSvg,
  awsSvg,
  forbesSvg,
  googlePartnerSvg,
  microsoftAzureSvg,
  readReviewsSvg
} from './svgs'

const LegalCopy = styled(Span)`
  color: #f6f9fc;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const LinksContainer = styled(Div)`
  -webkit-column-width: 206px;
  -moz-column-width: 206px;
  column-width: 206px;

  -webkit-column-gap: 62.5px;
  -moz-column-gap: 62.5px;
  column-gap: 62.5px;

  -webkit-column-fill: auto;
  -moz-column-fill: auto;
  column-fill: auto;
  /* border: solid 1px white; */

  height: 620px;
  @media all and (max-width: 1439px) {
    height: 850px;
  }
  @media all and (max-width: 1140px) {
    height: 1000px;
  }
  @media all and (max-width: 875px) {
    height: 1250px;
  }
  @media all and (max-width: 620px) {
    height: 1600px;
  }
  @media all and (max-width: 595px) {
    height: 2500px;
  }

  overflow: auto;
`

const JoinTheForefrontText = styled(H2)`
  font-family: 'DM Serif Display';
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  color: ${colors.LIGHT_GREY};
  @media ${devices.desktop} {
    font-size: 70px;
    line-height: 70px;
  }
  @media ${devices.tablet} {
    font-size: 60px;
    line-height: 60px;
  }
  @media ${devices.mobile} {
    font-size: 50px;
    line-height: 50px;
  }
`

const BadgeTitle = styled(P)`
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  color: #f6f9fc;
`

const BadgeImage = styled.img`
  width: 80px;
  height: 80px;

  @media ${devices.tablet} {
    width: 48px;
    height: 48px;
  }

  @media ${devices.mobile} {
    width: 48px;
    height: 48px;
  }
`

const FooterContainer = styled(Div)`
  background: url(${FooterBackground}) no-repeat center top, #0b1327;
  background-size: cover;
  background-origin: padding-box;

  @media ${devices.mobile} {
    background: url(${FooterBackgroundMobile}) no-repeat center top, #0b1327;
  }

  @media ${devices.tablet} {
    background: url(${FooterBackgroundTablet}) no-repeat center top, #0b1327;
  }
`

const FooterTitle = styled(Div)`
  color: #f6f9fc;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`

const HeaderWrapper = styled(Div)`
  @media ${breakpoints.tablet} {
    display: block;
  }
`

const LogosSection = ({ title, logos }) => {
  return (
    <Div>
      <FooterTitle>{title}</FooterTitle>
      <Div spaceBetween w={[432, 432, 423, '100%']} mt={20} h={64} gap='20px'>
        {logos.map((logo, index) => (
          <Div key={index}>
            <Div centered h100>
              {logo.url ? (
                <a href={logo.url} target="_blank" rel="noopener noreferrer">
                  {logo.svg}
                </a>
              ) : (
                logo.svg
              )}
            </Div>
          </Div>
        ))}
      </Div>
    </Div>
  )
}

const LogoPartnershipSection = styled(Div)`
  @media ${breakpoints.tablet} {
    display: block;
  }
`

const Footer = () => {
  const certificationUI = (
    <Div mt={[0, 0, 72, 72]}>
      <BadgeImage loading="lazy" src={HipaaImage} alt="hipaa-certificate" />
      <Div>
        <BadgeTitle mt={17}>We are HIPAA-compliant</BadgeTitle>
        <Div maxw={331} mt={16}>
          <LegalCopy>
            We build enterprise-grade web and mobile applications.
          </LegalCopy>
        </Div>
      </Div>
    </Div>
  )

  const headerUI = (
    <HeaderWrapper dflex spaceBetween w100 mt={120}>
      <Div>
        <JoinTheForefrontText maxw={[730, 630, 513, 296]} mr={50}>
          Join the forefront of innovation
        </JoinTheForefrontText>
        <Div maxw={296} mt={40}>
          <LetsWorkTogetherButton />
        </Div>
      </Div>
      <Div>{certificationUI}</Div>
    </HeaderWrapper>
  )

  const linksUI = (
    <LinksContainer mt={[120, 120, 82, 52]}>
      {footerLinks.map((linkSection, index) => (
        <Div key={index}>
          <FooterLinkSection {...linkSection} />
        </Div>
      ))}
    </LinksContainer>
  )

  return (
    <FooterContainer id="contact-us" centered>
      <GeneralWrapper>
        {headerUI}
        {linksUI}
        <LogoPartnershipSection spaceBetween mt={106}>
          <LogosSection
            title="Partnerships"
            logos={[
              { svg: awsSvg },
              { svg: googlePartnerSvg },
              { svg: microsoftAzureSvg }
            ]}
          />
          <Div mt={[0, 0, 50, 50]}>
            <LogosSection
              title="Recognized by"
              logos={[
                {
                  svg: readReviewsSvg,
                  url: 'https://clutch.co/profile/flatirons#highlights'
                },
                { svg: adobeSvg },
                { svg: forbesSvg }
              ]}
            />
          </Div>
        </LogoPartnershipSection>
        <Div mt={130} mb={48}>
          <Legal />
        </Div>
      </GeneralWrapper>
    </FooterContainer>
  )
}

export default Footer
