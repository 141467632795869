import { useContactDialogContext } from 'context/ContactDialogContextProvider'
import { usePostHogIntegration } from '../../hooks/usePostHog'
import { MainCTA } from './MainCTA'

export const LetsWorkTogetherButton = ({
  animationDelay = 0.6
}: LetsWorkTogetherButtonProps) => {
  const { setDialogOpen } = useContactDialogContext()
  const ctaCopy = usePostHogIntegration()

  return (
    <MainCTA
      onClick={() => setDialogOpen(true)}
      animationDelay={animationDelay}
      id="workTogetherCTA"
    >
      {ctaCopy || "Let's work together"}
    </MainCTA>
  )
}
type LetsWorkTogetherButtonProps = {
  animationDelay?: number
}
