export type FooterLinkPath = {
  path: string
  name: string
}

export type FooterLink = {
  title: string
  paths: FooterLinkPath[]
  url?: string
}

export const footerLinks: FooterLink[] = [
  {
    title: 'our company',
    paths: [
      {
        path: '/about-us',
        name: 'about us'
      },
      {
        path: '/contact-us',
        name: 'contact us'
      },
      {
        path: '/our-work',
        name: 'our work'
      }
    ]
  },
  {
    title: 'design services',
    paths: [
      {
        path: '/services/ui-ux-design/',
        name: 'UI/UX design'
      },
      {
        path: '/services/user-research/',
        name: 'user research & testing'
      }
    ]
  },
  {
    title: 'development services',
    paths: [
      {
        path: '/services/custom-software-development/',
        name: 'Custom Software'
      },
      {
        path: '/services/ecommerce-development/',
        name: 'ecommerce development'
      },
      {
        path: '/services/enterprise-software-development',
        name: 'Enterprise Software'
      },
      {
        path: '/services/iot-development/',
        name: 'IoT development'
      },
      {
        path: '/services/marketplace-development/',
        name: 'marketplace development'
      },
      {
        path: '/services/mobile-app-development/',
        name: 'mobile development'
      },
      {
        path: '/services/startup-mvp-development/',
        name: 'MVP Development'
      },
      {
        path: '/services/nearshore-software-outsourcing/',
        name: 'nearshore development'
      },
      {
        path: '/services/saas-software-development/',
        name: 'SaaS Development'
      },
      {
        path: '/services/web-development/',
        name: 'web development'
      }
    ]
  },
  {
    title: 'maintenance services',
    paths: [
      {
        path: '/services/agile-project-management/',
        name: 'project management'
      },
      {
        path: '/services/quality-assurance-testing/',
        name: 'Quality Assurance Testing'
      },
      {
        path: '/services/web-and-mobile-app-maintenance/',
        name: 'App Maintenance'
      }
    ]
  },
  {
    title: 'engagement models',
    paths: [
      {
        path: '/services/software-development-outsourcing/',
        name: 'Software Outsourcing'
      },
      {
        path: '/services/nearshore-staff-augmentation/',
        name: 'staff augmentation'
      }
    ]
  },
  {
    title: 'technologies',
    url: '/technologies',
    paths: [
      {
        path: '/technologies/flutter',
        name: 'flutter'
      },
      {
        path: '/technologies/node-js',
        name: 'node.js'
      },
      {
        path: '/technologies/reactjs',
        name: 'react'
      },
      {
        path: '/technologies/react-native',
        name: 'react native'
      },
      {
        path: '/technologies/ruby-on-rails',
        name: 'ruby on rails'
      }
    ]
  },
  {
    title: 'industries',
    paths: [
      {
        path: '/industries/fintech/',
        name: 'Fintech'
      },
      {
        path: '/industries/healthcare',
        name: 'healthcare'
      },
      {
        path: '/industries/legal-tech',
        name: 'legal tech'
      },
      {
        path: '/industries/real-estate',
        name: 'real estate'
      },
      {
        path: '/industries/transportation',
        name: 'transportation'
      }
    ]
  },
  {
    title: 'products',
    paths: [
      {
        path: '/csvbox-alternative/',
        name: 'CSVBox Alternative'
      },
      {
        path: '/flatfile-alternative',
        name: 'flatfile alternative'
      },
      {
        path: '/products/fuse',
        name: 'flatirons fuse'
      },
      {
        path: '/oneschema-alternative/',
        name: 'OneSchema Alternative'
      },
      {
        path: '/usecsv-alternative/',
        name: 'UseCSV Alternative'
      }
    ]
  },
  {
    title: 'Resources',
    paths: [
      {
        path: '/blog',
        name: 'blog'
      },
      {
        path: '/flatirons-fund',
        name: 'flatirons fund'
      }
    ]
  },
  {
    title: 'service areas',
    paths: [
      {
        path: '/atlanta/software-services/',
        name: 'atlanta'
      },
      {
        path: '/austin/software-services/',
        name: 'austin'
      },
      {
        path: '/boston/software-services/',
        name: 'boston'
      },
      {
        path: '/boulder/software-services/',
        name: 'boulder'
      },
      {
        path: '/chicago/software-services/',
        name: 'chicago'
      },
      {
        path: '/denver/software-services/',
        name: 'denver'
      },
      {
        path: '/los-angeles/software-services/',
        name: 'los angeles'
      },
      {
        path: '/miami/software-services/',
        name: 'miami'
      },
      {
        path: '/new-york/software-services/',
        name: 'new york'
      },
      {
        path: '/portland/software-services/',
        name: 'portland'
      },
      {
        path: '/san-francisco/software-services/',
        name: 'san francisco'
      },
      {
        path: '/seattle/software-services/',
        name: 'seattle'
      }
    ]
  }
]
